// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-clientele-jsx": () => import("./../../../src/pages/clientele.jsx" /* webpackChunkName: "component---src-pages-clientele-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sewage-treatment-plants-js": () => import("./../../../src/pages/sewage-treatment-plants.js" /* webpackChunkName: "component---src-pages-sewage-treatment-plants-js" */),
  "component---src-pages-swimming-pools-js": () => import("./../../../src/pages/swimming-pools.js" /* webpackChunkName: "component---src-pages-swimming-pools-js" */),
  "component---src-pages-water-treatment-plants-js": () => import("./../../../src/pages/water-treatment-plants.js" /* webpackChunkName: "component---src-pages-water-treatment-plants-js" */)
}

